import { createMandatoryContext } from '@change-corgi/core/react/context';

import type { MediaHitsContextValue } from './types';

export const {
	Context: MediaHitsContext,
	Provider: MediaHitsContextBaseProvider,
	useContext: useMediaHitsContext,
} = createMandatoryContext<MediaHitsContextValue>(undefined, {
	name: 'MediaHitsContext',
});
