import type { JSX } from 'react';

import { Link } from '@change-corgi/design-system/components/actions';
import { Text } from '@change-corgi/design-system/typography';

import type { PetitionDetails } from 'src/app/pages/petitionGamma/details/shared/types';

import { useUserProfileUrl } from '../../../../../../hooks/userProfileUrl';

export function UserLink({ user }: { user: PetitionDetails['user'] }): JSX.Element {
	const { data, actions } = useUserProfileUrl();

	if (data.isAnonymous) {
		return (
			<Text fontWeight="bold" color="typography-primary">
				{user.displayName}
			</Text>
		);
	}

	return (
		<Link
			color="typography-primary"
			data-testid="user-profile-page-link"
			onClick={actions.onUserNameLinkClick}
			to={data.urlLink}
		>
			{user.displayName}
		</Link>
	);
}
