import { useCallback, useState } from 'react';

import type { FormApi } from 'final-form';

import { useUtilityContext } from '@change-corgi/core/react/utilityContext';

import { usePetitionDetailsPageData } from 'src/app/pages/petitionGamma/details/pageContext';
import { useCampaignTracking } from 'src/app/shared/hooks/campaignTracking';

import { useMediaHitsContext } from '../../../../shared/context';
import type { MediaHitValues } from '../../../../shared/context';
import { createMediaHit } from '../../api/createMediaHit';
import { useValidateLink } from '../useValidateLink';

type Result = ModelHookResult<
	{ loading: boolean },
	{
		validateLink: (value: string) => string | undefined;
		handleSubmitMediaHit: (
			values: MediaHitValues,
			form: FormApi<MediaHitValues, Partial<MediaHitValues>>,
		) => Promise<void>;
	}
>;

// eslint-disable-next-line max-lines-per-function
export function useAddMediaHit(): Result {
	const [loading, setLoading] = useState(false);

	const {
		data: {
			petition: { id: petitionId },
		},
	} = usePetitionDetailsPageData();
	const {
		actions: { addMedia, setSubmitStatus },
	} = useMediaHitsContext();
	const validateLink = useValidateLink();

	const { track, trackError } = useCampaignTracking();
	const utilityContext = useUtilityContext();

	const handleSubmitMediaHit = useCallback(
		async (formValues: { link: string }, form: FormApi<MediaHitValues, Partial<MediaHitValues>>) => {
			const trackingData = {
				petition_id: petitionId,
				media_url: formValues.link,
			};
			try {
				setLoading(true);
				setSubmitStatus(undefined);
				const result = await createMediaHit(formValues.link, petitionId, utilityContext);
				if (result?.success) {
					setSubmitStatus('success');
					form.reset();
					if (result.comment) {
						addMedia(result?.comment.id, result?.comment.comment);
					}
					track('campaign_media_hit_create_success', trackingData);
				} else {
					setSubmitStatus('error');
					track('campaign_media_hit_create_failure', trackingData);
				}
			} catch (err) {
				setSubmitStatus('error');
				trackError('campaign_media_hit_create_error', err, {
					params: trackingData,
				});
			} finally {
				setLoading(false);
			}
		},
		[petitionId, addMedia, setSubmitStatus, track, trackError, utilityContext],
	);

	return {
		data: { loading },
		actions: {
			handleSubmitMediaHit,
			validateLink,
		},
	};
}
