import { usePetitionDetailsPageData } from 'src/app/pages/petitionGamma/details/pageContext';

type Result = ModelHookResult<{
	ask: string;
	displayed: boolean;
	slug: string;
	tag?: {
		id: string;
		name: string;
		slug: string;
	};
}>;

export function useTagBreadcrumbs(): Result {
	const {
		data: {
			petition: { ask, slug, tagsConnection },
		},
	} = usePetitionDetailsPageData();

	const displayed = !!tagsConnection?.nodes.length;

	const tag = tagsConnection?.nodes[0];

	return {
		data: {
			ask,
			displayed,
			slug,
			tag,
		},
	};
}
