import type { JSX } from 'react';

import { TranslatePlural } from '@change-corgi/core/react/i18n';
import { Box } from '@change-corgi/design-system/layout';
import { Text } from '@change-corgi/design-system/typography';

import { usePetitionSignatureCount } from '../../../hooks/petitionStats';

type Props = {
	textAlign: 'left' | 'center';
};

export function SignatureStatsVictoryContainer({ textAlign }: Props): JSX.Element {
	const { signatureCount } = usePetitionSignatureCount();

	return (
		<Box sx={{ textAlign }}>
			<Text size="small">
				<TranslatePlural
					value="fe.pages.petition_show.num_supporters_victory"
					replacements={{
						num_sigs: signatureCount.displayedLocalized,
					}}
					count={signatureCount.displayed}
				/>
			</Text>
		</Box>
	);
}
