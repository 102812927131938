import type { JSX } from 'react';

import { SetPageStateValue } from '@change-corgi/core/react/pageState';
import { Separator } from '@change-corgi/design-system/components/content';
import { Box, Container } from '@change-corgi/design-system/layout';

import { ResponsiveBox } from 'src/app/pages/petitionGamma/details/shared/components';
import { BeforeSuggestedPetitionsWaypoint } from 'src/app/pages/petitionGamma/details/shared/waypoints';
import { PetitionUnderReviewBanner } from 'src/app/shared/components/banners/petitionUnderReview';
import { MembershipBottomBanner } from 'src/app/shared/components/membershipBottomBanner';
import { SuggestedPetitionsLazy } from 'src/app/shared/components/suggestedPetitionsLazy';

import { usePetitionPageStateData } from '../hooks/pageStateData';
import { usePetitionDetails } from '../hooks/petitionDetails';
import { useTrackPromotedView } from '../hooks/promotions';
import { PageScrollPositionProvider } from '../hooks/scroll';
import { useShowNewlyCreatedPetitions } from '../hooks/showNewlyCreatedPetitions';
import { useShowSuggestedVictoriousPetitions } from '../hooks/showSuggestedVictoriousPetitions';
import { useTrackPageView } from '../hooks/tracking/pageView';

import { DmFeedBannerContainer } from './DmFeedBanner';
import { ExpiredSessionBanner } from './ExpiredSessionBanner';
import { HeadContainer } from './head/Head';
import { HeadingContainer } from './Heading';
import { MainContainer } from './Main';
import { RedirectUnpublished } from './RedirectUnpublished';
import { ScrollTrackingWaypointContainer } from './ScrollTrackingWaypoint';
import { StrengthTips } from './strengthTips/StrengthTips';
import { TagBreadcrumbs } from './TagBreadcrumbs';

export function DetailsContainer(): JSX.Element {
	useTrackPageView();
	useTrackPromotedView();
	const showSuggestedVictoriousPetitions = useShowSuggestedVictoriousPetitions();
	const showNewlyCreated = useShowNewlyCreatedPetitions();
	const petitionDetails = usePetitionDetails();
	const petitionPageStateData = usePetitionPageStateData();

	const underReview = petitionDetails.status === 'UNDER_REVIEW';

	return (
		<Box data-testid="details-container" mb={32}>
			<ExpiredSessionBanner />
			{underReview && <PetitionUnderReviewBanner />}
			<RedirectUnpublished />
			{/* FIXME: for consistency this should be rendered in DetailsWithContext with the other providers. */}
			<PageScrollPositionProvider>
				<SetPageStateValue name="petition" value={petitionPageStateData} />
				<HeadContainer />
				<DmFeedBannerContainer />
				<StrengthTips />
				{/* TODO(CHANGE-68180): This can use the design system default (large). */}
				<Container variant="small">
					<ResponsiveBox viewportSize="normal">
						<HeadingContainer />
					</ResponsiveBox>
					<MainContainer />
				</Container>
				<BeforeSuggestedPetitionsWaypoint />
				<ScrollTrackingWaypointContainer name="scroll_other_petitions_visible" />
				<Container variant="small">
					<Separator role="presentation" my={24} />
				</Container>
				<SuggestedPetitionsLazy
					showVictorious={showSuggestedVictoriousPetitions}
					showNewlyCreated={showNewlyCreated}
					petitionSlugOrId={petitionDetails.id}
					channel="petition_footer"
					sourceLocation="petition_footer"
				/>
			</PageScrollPositionProvider>
			<TagBreadcrumbs />
			<MembershipBottomBanner slug={petitionDetails.slug} sourceLocation="petition_membership_popup" />
		</Box>
	);
}
