import type { UtilityContext } from '@change-corgi/core/react/utilityContext';

import type { LikeSupporterCommentMutation, LikeSupporterCommentMutationVariables } from './likeComment.graphql';
import { LikeSupporterComment } from './likeComment.graphql';

export type LikeCommentInput = LikeSupporterCommentMutationVariables['input'];
export type LikeCommentResponse = Pick<LikeSupporterCommentMutation['likeComment'], 'success' | 'liked'>;

export async function likeComment(
	{ gql: { fetch } }: UtilityContext,
	{ commentId, like }: LikeCommentInput,
): Promise<LikeCommentResponse> {
	const { data } = await fetch<LikeSupporterCommentMutation, LikeSupporterCommentMutationVariables>({
		query: LikeSupporterComment,
		variables: {
			input: {
				commentId,
				like,
			},
		},
		rejectOnError: true,
	});

	if (!data?.likeComment.success) throw new Error(`Unable to like comment (${data?.likeComment?.reason || 'unknown'})`);

	return data.likeComment;
}
