import type { JSX } from 'react';

import { ResponsiveBox } from 'src/app/pages/petitionGamma/details/shared/components';
import type { PetitionPhoto } from 'src/app/pages/petitionGamma/details/shared/types';
import { ImagePreload } from 'src/app/shared/components/optimization';

import { useUserInfo } from '../../hooks/userInfo';
import { AddMediaContainer } from '../AddMedia';

import { Photo } from './components/Photo';
import { PhotoMobile } from './components/PhotoMobile';
import { PhotoProcessing } from './components/PhotoProcessing';

type Props = {
	photo?: PetitionPhoto;
};

export function MediaContainer({ photo }: Props): JSX.Element | null {
	const { canEdit } = useUserInfo();

	if (photo) {
		if (photo.processing) {
			return <PhotoProcessing />;
		}
		return (
			<>
				<ImagePreload url={[photo.mobileUrl, photo.url]} fetchPriority="high" />

				<ResponsiveBox viewportSize="small">
					<PhotoMobile id="petition-photo-mobile" url={photo.url} mobileUrl={photo.mobileUrl} />
				</ResponsiveBox>

				<ResponsiveBox viewportSize="normal">
					<Photo id="petition-photo" url={photo.url} mobileUrl={photo.mobileUrl} />
				</ResponsiveBox>
			</>
		);
	}

	if (canEdit) {
		return <AddMediaContainer />;
	}

	return null;
}
