import type { JSX } from 'react';

import type { PetitionDetails } from 'src/app/pages/petitionGamma/details/shared/types';

import { OrganizationLink } from './components/OrganizationLink';
import { UserLink } from './components/UserLink';

type Props = Readonly<{
	user: PetitionDetails['user'];
	organization: PetitionDetails['organization'];
}>;

export function StarterLink({ user, organization }: Props): JSX.Element {
	return organization ? <OrganizationLink organization={organization} /> : <UserLink user={user} />;
}
