import type { JSX, PropsWithChildren } from 'react';

import { Icon } from '@change-corgi/design-system/components/icon';
import type { IconComponent } from '@change-corgi/design-system/icons';
import { Box } from '@change-corgi/design-system/layout';
import { Heading } from '@change-corgi/design-system/typography';

type Props = {
	icon: IconComponent;
	iconColor: string;
};

export function SidePetitionStatus({ icon, iconColor, children }: PropsWithChildren<Props>): JSX.Element {
	return (
		<Box
			p={8}
			mt={8}
			mb={24}
			sx={{ textAlign: 'center', backgroundColor: 'primary-greyBackground', borderRadius: '8px' }}
		>
			<Heading as="h3" size={['h4', 'h3']}>
				<Box sx={{ position: 'relative', display: 'inline-block', bottom: -2 }} mr={8}>
					<Icon icon={icon} size="xl" color={iconColor} />
				</Box>
				{children}
			</Heading>
		</Box>
	);
}
