import React, { useMemo } from 'react';

import { Html } from '@change-corgi/design-system/html';
import { Box } from '@change-corgi/design-system/layout';

import { usePetitionDetailsPageData } from 'src/app/pages/petitionGamma/details/pageContext';
import { useRichMediaTransformers } from 'src/app/shared/hooks/richMedia';

import { useDescriptionTracking } from '../../../../hooks/tracking/description';

export const DescriptionContent: React.FC = () => {
	const { trackClickLink } = useDescriptionTracking();

	const {
		data: {
			petition: { description },
		},
	} = usePetitionDetailsPageData();

	const { decodeRichMediaTransformer } = useRichMediaTransformers();
	const htmlTransformers = useMemo(() => [decodeRichMediaTransformer], [decodeRichMediaTransformer]);

	return (
		<Box data-qa="description-content" data-testid="description-html-content">
			<Html
				size="default"
				breakWord
				html={description}
				sx={{
					iframe: {
						width: '100%',
						height: 'auto',
						aspectRatio: '16/9',
					},
				}}
				transformers={htmlTransformers}
				onClickLink={trackClickLink}
			/>
		</Box>
	);
};
