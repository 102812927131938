import { usePetitionDetailsPageData, usePetitionDetailsPageFcm } from 'src/app/pages/petitionGamma/details/pageContext';
import type { DecisionMaker } from 'src/app/pages/petitionGamma/details/shared/types';

import { type AllDmResponses } from '../../containers/decisionMakers/hooks/useDecisionMakers';

type Result = ModelHookResult<
	{ showDmStatus: boolean; notificationStatus: boolean; responseStatus: boolean; responseUrl: string },
	EmptyObject
>;

export function useDmStatus(decisionMaker?: DecisionMaker, allDmResponses: AllDmResponses = []): Result {
	const pageData = usePetitionDetailsPageData();
	const { dmStatusConfig } = usePetitionDetailsPageFcm();
	const {
		data: {
			petition: { id: pId, slug },
		},
	} = pageData;

	// See if there is an official response matching dm id or dm displayName.
	// Some reason, dm id could be different within the response object.
	const responseMatchedOnDmId = allDmResponses.find((r) => r.decisionMaker.id === decisionMaker?.id);
	const responseMatchedOnDmName = allDmResponses.find(
		(r) => r.decisionMaker.displayName === decisionMaker?.displayName,
	);
	const response = responseMatchedOnDmId || responseMatchedOnDmName;
	const dmOfficialResponse = !!response;

	const eligiblePetition = dmStatusConfig?.specificPetitions.find((p) => p.id === pId);
	const dmConfig = eligiblePetition?.dm_status.find((dm) => dm.id === decisionMaker?.id);

	const showDmStatus = !!dmStatusConfig?.enabled && !!eligiblePetition;

	// DM has been notified if
	// 1) there is an official response, or
	// 2) or they are listed in fc (e.g. petition update mentions them, email was sent)
	const notificationStatus = dmOfficialResponse || !!dmConfig;

	// DM has responded if
	// 1) there is an official response, or
	// 2) there is a petition update mentioning their response
	const responseStatus = dmOfficialResponse || !!dmConfig?.petition_update;

	const officialResponseUrl = response ? `/p/${slug}/responses/${response.id}` : '';
	const petitionUpdateUrl = dmConfig?.petition_update ? `/p/${slug}/u/${dmConfig.petition_update}` : '';
	const responseUrl = officialResponseUrl || petitionUpdateUrl;

	return { data: { showDmStatus, notificationStatus, responseStatus, responseUrl }, actions: {} };
}
