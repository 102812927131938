import { usePetitionDetailsPageData } from 'src/app/pages/petitionGamma/details/pageContext';
import type { DecisionMaker } from 'src/app/pages/petitionGamma/details/shared/types';

type Result = ModelHookResult<
	{
		decisionMakers: DecisionMaker[];
		totalCount: number;
	},
	EmptyObject
>;

export function usePrimaryDecisionMakerConnection(): Result {
	const {
		data: {
			petition: {
				primaryDecisionMakerConnection: { nodes: decisionMakers, totalCount },
			},
		},
	} = usePetitionDetailsPageData();

	return {
		data: {
			decisionMakers: [...decisionMakers],
			totalCount,
		},
		actions: {},
	};
}
