import type { Session, SessionUser } from '@change-corgi/core/session';

import { useCurrentUser, useCurrentUserRole, useIsStarter, useSession } from 'src/app/shared/hooks/session';

type UserState = {
	currentUser: SessionUser | undefined;
	userIsStaff: boolean;
	userIsStarter: boolean;
	session: Session;
};

export function useUserState(): UserState {
	const session = useSession();
	const currentUser = useCurrentUser();
	const userIsStaff = useCurrentUserRole('staff');
	const userIsStarter = useIsStarter();

	return {
		session,
		currentUser,
		userIsStaff,
		userIsStarter,
	};
}
