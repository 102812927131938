import { useCallback, useState } from 'react';

type Result = ModelHookResult<
	{
		isCollapsed: boolean;
	},
	{
		expand: () => void;
	}
>;

export function useIsCollapsed(): Result {
	const [isCollapsed, setIsCollapsed] = useState(true);

	const expand = useCallback(() => {
		setIsCollapsed(false);
	}, []);

	return {
		data: { isCollapsed },
		actions: { expand },
	};
}
