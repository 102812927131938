import React from 'react';

import { TranslatePlural, useI18n } from '@change-corgi/core/react/i18n';
import { ClientRender } from '@change-corgi/core/react/ssr/render';

type Props = {
	count: number;
};

export const UpdatesText: React.FC<Props> = ({ count }) => {
	const { localizeNumber } = useI18n();

	return (
		<ClientRender
			placeholder={
				<TranslatePlural
					value="corgi.petition_gamma.content_summary.updates"
					count={count}
					replacements={{ updatesCount: count.toString() }}
				/>
			}
		>
			{() => (
				<TranslatePlural
					value="corgi.petition_gamma.content_summary.updates"
					count={count}
					replacements={{ updatesCount: localizeNumber(count) }}
				/>
			)}
		</ClientRender>
	);
};
