import type { JSX } from 'react';

import { DetailsContainer } from '../containers/Details';

import { useTrackPageView } from './hooks/useTrackPageView';

export function Layout(): JSX.Element {
	useTrackPageView();

	return <DetailsContainer />;
}
