import { useMemo } from 'react';

import { usePetitionDetailsPageData } from 'src/app/pages/petitionGamma/details/pageContext';

export function usePetitionStarterPhoto() {
	const {
		data: {
			petition: { organization, user },
		},
	} = usePetitionDetailsPageData();
	const photo = useMemo(
		() => (organization ? organization.photo?.organizationSmall : user.photo?.userSmall),
		[organization, user],
	);
	const photoUrl = useMemo(() => (photo?.processing ? undefined : photo?.url), [photo]);

	return { photoUrl };
}
