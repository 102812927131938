import { useMemo } from 'react';

import { usePetitionDetailsPageData } from 'src/app/pages/petitionGamma/details/pageContext';

import { usePrimaryDecisionMakerConnection } from '../../../decisionMakers/hooks/usePrimaryDecisionMakerConnection';

type Result = ModelHookResult<{
	sectionIsShown: boolean;
	decisionMakers: {
		isShown: boolean;
		count: number;
		first?: { displayName: string };
	};
	mediaHits: {
		isShown: boolean;
		count: number;
	};
	updates: {
		isShown: boolean;
		count: number;
	};
}>;

export const useContentSummary = (): Result => {
	const { data: decisionMakersConnection } = usePrimaryDecisionMakerConnection();

	const {
		data: {
			petition: { petitionUpdatesConnection },
			mediaHitsPage: { mediaHits: mediaHitComments },
		},
	} = usePetitionDetailsPageData();

	const decisionMakers = useMemo(() => {
		return {
			isShown: decisionMakersConnection.totalCount > 0,
			count: decisionMakersConnection.totalCount,
			first: decisionMakersConnection.decisionMakers[0],
		};
	}, [decisionMakersConnection]);

	const mediaHits = useMemo(() => {
		return {
			isShown: (mediaHitComments?.length ?? 0) > 0,
			count: mediaHitComments?.length ?? 0,
		};
	}, [mediaHitComments]);

	const updates = useMemo(() => {
		const { totalCount } = petitionUpdatesConnection;

		return {
			isShown: totalCount > 0,
			count: totalCount,
		};
	}, [petitionUpdatesConnection]);

	return useMemo(() => {
		return {
			data: {
				sectionIsShown: decisionMakers.isShown || mediaHits.isShown || updates.isShown,
				decisionMakers,
				mediaHits,
				updates,
			},
			actions: {},
		};
	}, [decisionMakers, mediaHits, updates]);
};
