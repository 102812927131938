import { useCallback, useState } from 'react';

import { useI18n } from '@change-corgi/core/react/i18n';
import { useNavigate } from '@change-corgi/core/react/router';
import { useUtilityContext } from '@change-corgi/core/react/utilityContext';

import { useLoginRedirectUrl } from 'src/app/shared/hooks/auth';
import { useCampaignTracking } from 'src/app/shared/hooks/campaignTracking';
import { useLoginState } from 'src/app/shared/hooks/session';

import { likeComment } from './likeComment';

export type PartialComment = {
	id: string;
	viewerLiked: boolean;
	likes: number;
	likesLocalized: string;
	createdAtLocalized: string;
	user: {
		displayName: string;
	};
};

type Result = ModelHookResult<
	{
		likes: string;
		liked: boolean;
		a11yLabel: string;
		likesText: string;
	},
	{
		toggleLiked: () => Promise<void>;
	}
>;

type LikeState = {
	liked: boolean;
	likes: number;
	likesLocalized: string;
};

export type Options = {
	comment: PartialComment;
	onLikeToggled?: (liked: boolean) => void;
};

export function useCommentLikes({ comment, onLikeToggled }: Options): Result {
	const [likeState, setLikeState] = useLikeState(comment, onLikeToggled);
	const { localizeNumber } = useI18n();
	const utilityContext = useUtilityContext();
	const loginState = useLoginState();
	const trackLiked = useTrackLiked(comment);
	const navigateToLogin = useNavigateToLogin();

	return {
		data: {
			likes: likeState.likesLocalized,
			liked: likeState.liked,
			a11yLabel: useA11yLabel(comment, likeState),
			likesText: useLikesText(likeState),
		},
		actions: {
			toggleLiked: useCallback(async () => {
				if (loginState === 'GUEST') {
					navigateToLogin();
					return;
				}

				const liked = !likeState.liked;
				const oldState = likeState;
				const newState = {
					liked,
					likes: likeState.likes + (liked ? 1 : -1),
					likesLocalized: localizeNumber(likeState.likes + (liked ? 1 : -1)),
				};
				setLikeState(newState);
				try {
					await likeComment(utilityContext, { commentId: comment.id, like: liked });
					if (liked) trackLiked({ likes: newState.likes });
				} catch (e) {
					// no feedback on error on purpose
					setLikeState(oldState);
				}
			}, [loginState, navigateToLogin, comment, likeState, utilityContext, localizeNumber, trackLiked, setLikeState]),
		},
	};
}

function useLikeState(comment: PartialComment, onLikeToggled: ((liked: boolean) => void) | undefined) {
	const [likeState, setLikeState] = useState<LikeState>({
		liked: comment.viewerLiked,
		likes: comment.likes,
		likesLocalized: comment.likesLocalized,
	});
	return [
		likeState,
		useCallback(
			(state: LikeState) => {
				setLikeState(state);
				onLikeToggled?.(state.liked);
			},
			[onLikeToggled],
		),
	] as const;
}

function useA11yLabel(comment: PartialComment, likeState: LikeState) {
	const { translatePlural } = useI18n();

	return translatePlural(
		`corgi.petition.details.comments.actions.${likeState.liked ? 'unlike' : 'like'}_a11y`,
		likeState.likes,
		{ author: comment.user.displayName, date: comment.createdAtLocalized, likesCount: likeState.likesLocalized },
	);
}

function useLikesText(likeState: LikeState) {
	const { translatePlural } = useI18n();

	return translatePlural('corgi.petition_gamma.comments.likes', likeState.likes, {
		likesCount: likeState.likesLocalized,
	});
}

function useTrackLiked(comment: PartialComment) {
	const { track } = useCampaignTracking();

	return useCallback(
		({ likes }: { likes: number }) => {
			void track('petition_gamma_supporter_comment_like_click', {
				likes,
				comment_id: comment.id,
			});
		},
		[track, comment],
	);
}

function useNavigateToLogin() {
	const navigate = useNavigate();
	const loginPath = useLoginRedirectUrl();

	return useCallback(() => navigate(loginPath), [navigate, loginPath]);
}
