import type { ComponentProps, JSX } from 'react';

import { Icon } from '@change-corgi/design-system/components/icon';
import { iconFavorite, iconFavoriteBorder } from '@change-corgi/design-system/icons';
import { Box } from '@change-corgi/design-system/layout';
import { buttonResetStyles } from '@change-corgi/design-system/reset';
import { Text } from '@change-corgi/design-system/typography';

import type { PartialComment } from './hooks/useCommentLikes';
import { useCommentLikes } from './hooks/useCommentLikes';

type Props = {
	comment: PartialComment;
	onLikeToggled?: (liked: boolean) => void;
	size?: ComponentProps<typeof Text>['size'];
};

export function CommentLikes({
	comment,
	onLikeToggled,
	size,
	...rest
}: Props & ComponentProps<typeof Box>): JSX.Element {
	const {
		data: { liked, a11yLabel, likesText },
		actions: { toggleLiked },
	} = useCommentLikes({ comment, onLikeToggled });

	return (
		<Box sx={{ display: 'flex', alignItems: 'center' }} {...rest}>
			<button
				type="button"
				sx={buttonResetStyles}
				onClick={toggleLiked}
				data-testid={`toggle-like-button-comment-${comment.id}`}
				data-qa="comment-toggle-like"
				aria-label={a11yLabel}
			>
				<Text
					sx={{ display: 'flex', alignItems: 'center', gap: 8 }}
					size={size}
					color={liked ? 'typography-highlight' : 'typography-secondary'}
				>
					<Icon icon={liked ? iconFavorite : iconFavoriteBorder} sx={{ fontSize: 'inherit' }} />
					{likesText}
				</Text>
			</button>
		</Box>
	);
}
