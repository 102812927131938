/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React from 'react';

import { Box, Flex } from '@change-corgi/design-system/layout';
import { Heading } from '@change-corgi/design-system/typography';

import { SupporterVoicesComments } from '../supporterVoicesComments';

import { SupporterVoicesVideos } from './components/SupporterVoicesVideos';
import { useSupporterVoicesSection } from './hooks/useSupporterVoicesSection';

export const SupporterVoices: React.FC = () => {
	const {
		data: { showSupporterVoicesSection },
	} = useSupporterVoicesSection();

	if (!showSupporterVoicesSection) {
		return <Box sx={{ display: 'none' }} data-testid="petition-page-supporters-voices-hidden" />;
	}

	return (
		<Flex mb={[24, 0]} sx={{ flexDirection: 'column', gap: 24 }} data-testid="petition-page-supporters-voices">
			<Heading as="h2" size="h3">
				{/* TODO(CHANGE-67982): Replace with i18n key */}
				The Supporters
			</Heading>
			<SupporterVoicesComments />

			<SupporterVoicesVideos />
		</Flex>
	);
};
