import type { PropsWithChildren } from 'react';

import { MediaHitsContextBaseProvider } from './context';
import { useMediaHitsContextValue } from './useMediaHitsContextValue';

export function MediaHitsContextProvider({ children }: PropsWithChildren) {
	const value = useMediaHitsContextValue();

	return <MediaHitsContextBaseProvider value={value}>{children}</MediaHitsContextBaseProvider>;
}
