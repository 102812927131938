import React from 'react';

import { Translate } from '@change-corgi/core/react/i18n';
import { Icon } from '@change-corgi/design-system/components/icon';
import { iconOutlinedFlag } from '@change-corgi/design-system/icons';
import { Flex } from '@change-corgi/design-system/layout';
import { buttonResetStyles } from '@change-corgi/design-system/reset';
import { Text } from '@change-corgi/design-system/typography';

import { ReportAbuseLink } from 'src/app/shared/components/abuse';

import type { SupporterComment } from '../../shared/types';

import { useReportComment } from './hooks/useReportComment';

type ReportCommentProps = {
	comment: SupporterComment;
	petitionId: string;
	petitionSlug: string;
};
export function ReportComment({ comment, petitionId, petitionSlug }: ReportCommentProps): React.JSX.Element | null {
	const {
		data: { canReportComment, a11yLabel },
		actions: { handleReportClick },
	} = useReportComment({ petitionId, comment });

	if (!canReportComment) return null;

	return (
		<Flex onClick={handleReportClick} sx={{ gap: '10px' }}>
			<Text color="typography-secondary">·</Text>

			<ReportAbuseLink
				type="supporter_comment"
				comment={{
					id: comment.id,
					likes: comment.likes,
					role: comment.role,
				}}
				petitionId={petitionId}
				petitionSlug={petitionSlug}
			>
				<button
					type="button"
					sx={buttonResetStyles}
					aria-label={a11yLabel}
					data-testid={`supporter-comment-${comment.id}-report-button`}
				>
					<Text color="typography-secondary" sx={{ display: 'flex', alignItems: 'center', gap: 8 }}>
						<Icon icon={iconOutlinedFlag} sx={{ fontSize: 'inherit' }} />
						<Translate value="fe.components.comments.report" />
					</Text>
				</button>
			</ReportAbuseLink>
		</Flex>
	);
}
