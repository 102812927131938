import type { SVGProps } from 'react';

export const SignatureDistributionIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 31 30" {...props}>
		<circle cx={15.5} cy={14.246} r={1.75} stroke="currentColor" />
		<circle cx={15.5} cy={7.246} r={2.75} stroke="currentColor" />
		<path
			stroke="currentColor"
			d="m8.749 8.295.001-.024v-.025a6.75 6.75 0 0 1 13.5 0c0 2.332-.942 4.877-2.253 7.242-1.416 2.552-3.213 4.805-4.533 6.216-2.233-2.063-3.766-4.211-4.799-6.198-1.637-3.152-2.005-5.881-1.916-7.21ZM3 24.746c-.667 0-2-.4-2-2v-2.5c0-.667.4-2 2-2h2M8 24.746c.667 0 2-.4 2-2v-2.5c0-.667-.4-2-2-2H5"
		/>
		<circle cx={5.5} cy={14.246} r={1.75} stroke="currentColor" />
		<path
			stroke="currentColor"
			d="M3.5 21.746v7M7.5 21.746v7M23 24.746c-.667 0-2-.4-2-2v-2.5c0-.667.4-2 2-2h2M28 24.746c.667 0 2-.4 2-2v-2.5c0-.667-.4-2-2-2h-3M23.5 21.746v7M27.5 21.746v7"
		/>
		<circle cx={25.5} cy={14.246} r={1.75} stroke="currentColor" />
		<path fill="#fff" d="M11 18.746h9v6h-9z" />
		<path
			stroke="currentColor"
			d="M13 24.746c-.667 0-2-.4-2-2v-2.5c0-.667.4-2 2-2h2M18 24.746c.667 0 2-.4 2-2v-2.5c0-.667-.4-2-2-2h-3M13.5 21.746v7M17.5 21.746v7"
		/>
	</svg>
);
