import type { JSX } from 'react';

import { Image } from '@change-corgi/design-system/components/media';
import { Box } from '@change-corgi/design-system/layout';

type Props = Readonly<{
	id: string;
	url: string;
	mobileUrl: string;
}>;

export function PhotoMobile({ id, url, mobileUrl }: Props): JSX.Element {
	return (
		<Box sx={{ width: '100%', position: 'relative' }}>
			<Image
				fetchPriority="high"
				// The ratio we generate from uploaded images
				aspectRatio="25/14"
				fit="cover"
				width="100%"
				id={id}
				src={[mobileUrl, url]}
				alt=""
				sx={{
					// removes an unwanted padding under the image
					verticalAlign: 'top',
				}}
			/>
			{/* Apply a gradient mask over the image so that an image with a white background has a sharp bottom edge.  */}
			<Box
				sx={{
					position: 'absolute',
					top: 0,
					width: '100%',
					height: '100%',
					mixBlendMode: 'multiply',
					background: 'linear-gradient(#FFFFFF 71.5%, #CACACA 100%)',
				}}
			/>
		</Box>
	);
}
