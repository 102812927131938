import React from 'react';

import { Translate, useI18n } from '@change-corgi/core/react/i18n';
import { ButtonLink } from '@change-corgi/design-system/components/actions';

import type { DecisionMaker } from 'src/app/pages/petitionGamma/details/shared/types';

import type { AllDmResponses } from '../../../../hooks/useDecisionMakers';
import { useDmResponse } from '../../shared/hooks/useDmResponse';

type DmResponseProps = {
	decisionMaker: DecisionMaker;
	allDmResponses: AllDmResponses;
};

export function ReadDmResponse({ decisionMaker, allDmResponses }: DmResponseProps): React.JSX.Element | null {
	const {
		data: { dmHasResponded, firstDmResponseUrl },
		actions: { handleReadResponseClick },
	} = useDmResponse({ decisionMaker, allDmResponses });

	const { translate } = useI18n();

	return (
		<>
			{dmHasResponded && (
				<ButtonLink
					to={firstDmResponseUrl}
					aria-label={translate('corgi.petition_gamma.decision_makers.read_response')}
					target="blank"
					data-testid="read-dm-response"
					data-qa="read-dm-response"
					variant="secondaryEmphasis"
					sx={{ width: '100%' }}
					onClick={handleReadResponseClick}
				>
					<Translate value="corgi.petition_gamma.decision_makers.read_response" />
				</ButtonLink>
			)}
		</>
	);
}
