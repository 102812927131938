import { useCallback } from 'react';

import { useLocation } from 'react-router';

import { useNavigate } from '@change-corgi/core/react/router';

import { usePetitionDetailsPageData } from 'src/app/pages/petitionGamma/details/pageContext';
import { useCampaignTracking } from 'src/app/shared/hooks/campaignTracking';
import { useLoginState } from 'src/app/shared/hooks/session';

import type { MediaHitEmbed } from '../../../shared/context/types';
import { useMediaHitsPermissions } from '../../../shared/hooks/useMediaHitsPermissions';

import { useDeleteMediaModal } from './useDeleteMediaModal';

type Result = ModelHookResult<
	{ canDeleteMediaHit: boolean },
	{
		handleDeleteClick: () => Promise<void>;
	}
>;

export function useDeleteMediaHit(media: MediaHitEmbed, removeMedia: (mediaId: string) => void): Result {
	const { track } = useCampaignTracking();
	const {
		data: {
			petition: { id: petitionId },
		},
	} = usePetitionDetailsPageData();
	const loginState = useLoginState();
	const location = useLocation();
	const navigate = useNavigate();
	const {
		data: { canDelete },
	} = useMediaHitsPermissions();

	const openModal = useDeleteMediaModal();

	const openDeleteModal = useCallback(
		async (mediaHit: MediaHitEmbed) => {
			const result = await openModal({
				petitionId,
				media: mediaHit,
			}).waitForResult();

			if (result?.value === 'success') {
				removeMedia(mediaHit.id);
			}
		},
		[openModal, petitionId, removeMedia],
	);

	const handleDeleteClick = useCallback(async () => {
		void track('campaign_media_hit_delete_button_click', {
			petition_id: petitionId,
			media_id: media.id,
			loginState,
		});
		if (loginState === 'IDENTIFIED') {
			navigate(`/login_or_join/redirected?redirectTo=${location.pathname}`);
		} else {
			await openDeleteModal(media);
		}
	}, [location, loginState, navigate, openDeleteModal, petitionId, track, media]);

	return {
		data: {
			canDeleteMediaHit: canDelete,
		},
		actions: {
			handleDeleteClick,
		},
	};
}
