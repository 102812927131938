export const MicrophoneIcon = (props: React.SVGProps<SVGSVGElement>) => (
	<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 32 32" {...props}>
		<path d="M7.692 5.15A11.338 11.338 0 0 1 15.571 2c3.071 0 5.85 1.203 7.879 3.15a1 1 0 1 0 1.385-1.443A13.338 13.338 0 0 0 15.57 0a13.338 13.338 0 0 0-9.264 3.707A1 1 0 1 0 7.692 5.15Z" />
		<path d="M15.57 5.429c-2.084 0-3.961.777-5.323 2.023a1 1 0 0 1-1.35-1.475c1.73-1.582 4.087-2.548 6.673-2.548 2.587 0 4.945.966 6.674 2.548a1 1 0 1 1-1.35 1.475c-1.362-1.246-3.239-2.023-5.323-2.023ZM23.142 18.286c0 3.802-3.324 7-7.572 7-4.247 0-7.571-3.198-7.571-7a1 1 0 1 0-2 0c0 4.567 3.581 8.254 8.143 8.9V30h-3.714a1 1 0 1 0 0 2h9.714a1 1 0 1 0 0-2h-4v-2.73c4.962-.277 9-4.135 9-8.984a1 1 0 1 0-2 0Z" />
		<path
			fillRule="evenodd"
			d="M9.429 18.316c.018 3.17 2.874 5.541 6.142 5.541 3.279 0 6.143-2.387 6.143-5.571v-5.858c0-3.184-2.864-5.571-6.143-5.571s-6.143 2.387-6.143 5.571v5.888Zm6.57.934h3.558c-.478 1.45-2.008 2.607-3.986 2.607-2.01 0-3.557-1.195-4.008-2.678h1.15a.75.75 0 0 0 0-1.5h-1.285v-1.5h1.286a.75.75 0 0 0 0-1.5h-1.286v-1.5h1.286a.75.75 0 0 0 0-1.5h-1.192c.393-1.55 1.974-2.822 4.049-2.822 2.106 0 3.704 1.312 4.066 2.893h-3.638a.75.75 0 0 0 0 1.5h3.715v1.5h-3.715a.75.75 0 0 0 0 1.5h3.715v1.5h-3.715a.75.75 0 0 0 0 1.5Z"
			clipRule="evenodd"
		/>
	</svg>
);
