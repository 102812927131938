export const NewspaperHeartMiddleIcon = (props: React.SVGProps<SVGSVGElement>) => (
	<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 32 32" {...props}>
		<path
			fillRule="evenodd"
			d="M19.077 5.02H5v11.294h14.077V5.02ZM12 8.455S12.75 7 14.271 7A2.23 2.23 0 0 1 16.5 9.2c.025 2.485-2.033 4.252-4.29 5.737a.384.384 0 0 1-.42 0C9.532 13.452 7.473 11.685 7.5 9.2A2.23 2.23 0 0 1 9.73 7C11.25 7 12 8.455 12 8.455Z"
			clipRule="evenodd"
		/>
		<path d="M5 18.823a1 1 0 0 1 1-1h12a1 1 0 0 1 0 2H6a1 1 0 0 1-1-1ZM5 22.588a1 1 0 0 1 1-1h12a1 1 0 0 1 0 2H6a1 1 0 0 1-1-1ZM5 26.353a1 1 0 0 1 1-1h12a1 1 0 1 1 0 2H6a1 1 0 0 1-1-1Z" />
		<path
			fillRule="evenodd"
			d="M1 0a1 1 0 0 0-1 1v25.883c0 .85.252 2.1 1.034 3.165C1.853 31.163 3.202 32 5.2 32H27a1 1 0 0 0 .2-.02c1.756-.134 2.97-.928 3.736-1.959C31.74 28.94 32 27.667 32 26.801V7a1 1 0 0 0-1-1l-7-.004v-5a1 1 0 0 0-1-1L1 0Zm23 7.997v18c0 1.028.265 2.06.792 2.806.475.673.983 1.152 2.053 1.194 1.308-.044 2.046-.576 2.486-1.168.495-.668.669-1.495.669-2.029V8l-6-.003Zm-.842 21.96.03.04L5.2 30c-1.362 0-2.113-.535-2.554-1.136-.478-.65-.646-1.46-.646-1.982V2l20-.003v24c0 1.326.335 2.793 1.158 3.96Z"
			clipRule="evenodd"
		/>
	</svg>
);
