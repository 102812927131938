import type { JSX } from 'react';

import { Flex } from '@change-corgi/design-system/layout';

import { usePetitionDetails } from '../../hooks/petitionDetails';
import { usePromoteCtaDisplayed } from '../../hooks/promoteCta';
import { usePageScrollPosition } from '../../hooks/scroll';
import { useUserInfo } from '../../hooks/userInfo';
import { PetitionStatusHeaderContainer } from '../PetitionStatusHeader';
import { SignatureStatsVictoryContainer } from '../stats/signatureStats';

import { MobileComboButtonContainer } from './MobileComboButton';
import { MobileRespondButtonContainer } from './MobileRespondButton';
import { MobileShareButtonContainer } from './MobileShareButton';
import { MobileSignButtonContainer } from './MobileSignButton';

type Props = {
	sticky: boolean;
};

export function MobileActionButtonContainer({ sticky }: Props): JSX.Element | null {
	const { beforeSuggestedPetitions, afterDescription, afterMobileActionButton } = usePageScrollPosition();
	const { signed, decisionMaker } = useUserInfo();
	const { status } = usePetitionDetails();
	const promoteCtaDisplayed = usePromoteCtaDisplayed();

	// non-sticky button is always visible
	// sticky button is only visible if page has scrolled after the non-sticky button and before suggested petitions
	// !afterDescription condition allows sticky button to show in petitions with short descriptions
	const visible = sticky ? !!afterMobileActionButton && (beforeSuggestedPetitions || !afterDescription) : true;

	if (decisionMaker) {
		return <MobileRespondButtonContainer visible={visible} sticky={sticky} />;
	}

	if (status === 'CLOSED') {
		return <MobileShareButtonContainer visible={visible} sticky={sticky} hideSocialProof />;
	}

	if (status === 'VICTORY') {
		return (
			<Flex mb={24} sx={{ gap: '20px', flexDirection: 'column' }}>
				{!sticky && (
					<>
						<PetitionStatusHeaderContainer />
						<SignatureStatsVictoryContainer textAlign="center" />
					</>
				)}
				<MobileShareButtonContainer visible={visible} sticky={sticky} hideSocialProof />
			</Flex>
		);
	}

	if (signed) {
		return promoteCtaDisplayed ? (
			<MobileComboButtonContainer visible={visible} sticky={sticky} />
		) : (
			<MobileShareButtonContainer visible={visible} sticky={sticky} />
		);
	}

	return <MobileSignButtonContainer visible={visible} sticky={sticky} />;
}
