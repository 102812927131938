import React, { useEffect, useState } from 'react';

import { SectionMessage } from '@change-corgi/design-system/components/alerts';
import { Box } from '@change-corgi/design-system/layout';

import { useMediaHitsContext } from '../../../../shared/context';

export function MediaSubmitMessages(): React.JSX.Element | null {
	const [showMessage, setShowMessage] = useState(false);

	const {
		data: { submitStatus },
		actions: { setSubmitStatus },
	} = useMediaHitsContext();

	useEffect(() => {
		if (submitStatus) {
			setShowMessage(true);
		} else {
			setShowMessage(false);
		}
	}, [submitStatus]);

	const handleClose = () => {
		setShowMessage(false);
		setSubmitStatus(undefined);
	};

	if (!showMessage) return null;

	return (
		<Box my={16}>
			{submitStatus === 'success' && <SectionMessage variant="success" title="Media hit added" onClose={handleClose} />}
			{submitStatus === 'error' && (
				<SectionMessage variant="critical" title="Something went wrong" onClose={handleClose} />
			)}
		</Box>
	);
}
