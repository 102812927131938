import { usePetitionDetailsPageFcm } from 'src/app/pages/petitionGamma/details/pageContext';

import { DecisionMakerCardsContainer } from './DecisionMakerCardsContainer';
import { DecisionMakersContainer } from './DecisionMakers';

export function DecisionMakersMainWrapper() {
	const { dmCardConfig } = usePetitionDetailsPageFcm();

	return dmCardConfig?.enabled ? <DecisionMakerCardsContainer /> : <DecisionMakersContainer />;
}
