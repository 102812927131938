import type { JSX } from 'react';

import { useI18n } from '@change-corgi/core/react/i18n';
import { Icon } from '@change-corgi/design-system/components/icon';
import { iconVerified } from '@change-corgi/design-system/icons';
import { Text } from '@change-corgi/design-system/typography';

import type { PetitionDetails } from 'src/app/pages/petitionGamma/details/shared/types';

export function OrganizationLink({
	organization,
}: {
	organization: NonNullable<PetitionDetails['organization']>;
}): JSX.Element {
	const { translate } = useI18n();

	return (
		<Text sx={{ fontWeight: 'bold' }}>
			{organization.name}
			{organization.verificationStatus === 'VERIFIED' ? (
				<Icon
					icon={iconVerified}
					color="secondary-green"
					size="s"
					role="img"
					aria-label={translate('fe.pages.organization.verified_label')}
					sx={{ display: 'inline-block' }}
				/>
			) : null}
		</Text>
	);
}
