import React from 'react';

import { Translate } from '@change-corgi/core/react/i18n';
import { ButtonLink } from '@change-corgi/design-system/components/actions';
import { Avatar } from '@change-corgi/design-system/components/media';
import { Flex } from '@change-corgi/design-system/layout';
import { Text } from '@change-corgi/design-system/typography';

import { usePetitionDetailsPageData } from 'src/app/pages/petitionGamma/details/pageContext';

import { usePetitionStarterMediaInquiry } from '../../hooks/petitionStarterMediaInquiry';
import { usePetitionStarterPhoto } from '../../hooks/petitionStarterPhoto';

import { StarterLink } from './components/StarterLink';

const AVATAR_SIZE = 64;

export function PetitionStarter(): React.JSX.Element {
	const {
		data: {
			petition: { id, organization, user },
		},
	} = usePetitionDetailsPageData();
	const { photoUrl } = usePetitionStarterPhoto();
	const {
		data: { enabled, url },
		actions: { handleMediaInquiryClick },
	} = usePetitionStarterMediaInquiry(id);

	return (
		<Flex
			variant="bordered"
			sx={{ gap: 8, borderColor: 'neutral-grey400', flexDirection: 'column' }}
			mb={16}
			mt={24}
			p={16}
		>
			<Flex sx={{ gap: 16 }}>
				<Avatar variant="subtle" alt="avatar of the starter" src={photoUrl} size={AVATAR_SIZE} />
				<Flex
					sx={{
						flex: 1,
						// min-height + center so that 1 line is centered on the avatar
						// and 3+ lines are aligned with the avatar at the top
						minHeight: AVATAR_SIZE,
						flexDirection: 'column',
						alignItems: 'left',
						gap: 8,
					}}
				>
					<Flex sx={{ flexDirection: 'column' }}>
						<StarterLink user={user} organization={organization} />
						<Text>
							<Translate value="corgi.petition_gamma.details.starter_text" />
						</Text>
					</Flex>
				</Flex>
			</Flex>
			{enabled && (
				<ButtonLink data-testid="media-inquiry" to={url} variant="secondary" mt={20} onClick={handleMediaInquiryClick}>
					Media inquiries
				</ButtonLink>
			)}
		</Flex>
	);
}
