import { type ComponentPropsWithoutRef, type JSX } from 'react';

import { Box } from '@change-corgi/design-system/layout';
import { Text } from '@change-corgi/design-system/typography';

type Props = ComponentPropsWithoutRef<typeof Box> & {
	title: string;
	time: string;
};

export function ActivityPill({ title, time, sx, ...rest }: Props): JSX.Element {
	return (
		<Box
			sx={{
				display: 'inline-flex',
				backgroundColor: 'primary-greyBackground',
				borderRadius: '1000rem',
				gap: 4,
				paddingX: 12,
				paddingY: 8,
				...sx,
			}}
			{...rest}
		>
			<Text size="small">{title}</Text>
			<Text size="small" color="typography-secondary">
				•
			</Text>
			<Text size="small" color="typography-secondary">
				{time}
			</Text>
		</Box>
	);
}
