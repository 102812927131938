import { Translate } from '@change-corgi/core/react/i18n';
import { Separator } from '@change-corgi/design-system/components/content';
import { Box } from '@change-corgi/design-system/layout';
import { Text } from '@change-corgi/design-system/typography';

import { MIN_SIGNERS } from '../../constants';
import { useRecentSignersConnection } from '../../hooks/useRecentSignersConnection';
import { AccessibleDescription } from '../AccessibleDescription';
import { ActivityPill, ActivityScroller } from '../ActivityScroller';

export const RecentSignersViewportNormal: React.FC = () => {
	const recentSigners = useRecentSignersConnection();

	if (recentSigners.length < MIN_SIGNERS) {
		return null;
	}

	return (
		<Box data-testid="recent-signers-desktop">
			<Box mb={8}>
				<Text fontWeight="bold" size="caption">
					<Translate value="corgi.petition_gamma.recent_signers.title" />
				</Text>
			</Box>

			<Box
				sx={{
					// Make this the containing block for the gradient overlay:
					position: 'relative',
				}}
			>
				<ActivityScroller aria-hidden>
					{recentSigners.map((signer, index) => (
						<ActivityPill
							key={signer.id}
							title={signer.displayName}
							time={signer.timestampRelativeLocalized}
							sx={{
								marginLeft: index === 0 ? 0 : 16,
							}}
						/>
					))}
				</ActivityScroller>

				<AccessibleDescription />

				<Box
					sx={{
						position: 'absolute',
						top: 0,
						pointerEvents: 'none',
						background: 'linear-gradient(to right, transparent 80%, white);',
						width: '100%',
						height: '100%',
					}}
				/>
			</Box>

			<Separator role="presentation" my={24} />
		</Box>
	);
};
