import { useMemo } from 'react';

import type { SupporterComment } from '../../../../../../shared/types';

const DESCRIPTION_LENGTH_THRESHOLD = 350;

export function useIsCommentTooLong(supporterComment: SupporterComment) {
	const isCommentTooLong = useMemo(
		() => supporterComment.comment.length > DESCRIPTION_LENGTH_THRESHOLD,
		[supporterComment],
	);

	return isCommentTooLong;
}
