import { useMemo } from 'react';

import { usePetitionDetailsPageData } from 'src/app/pages/petitionGamma/details/pageContext';

import type { SupporterComment, SupporterCommentPetition, SupporterCommentsList } from '../shared/types';

type Result = ModelHookResult<
	{
		supporterComments: SupporterCommentsList;
		petition: SupporterCommentPetition;
	},
	EmptyObject
>;

export function useSupporterComments(): Result {
	const {
		data: { petition },
	} = usePetitionDetailsPageData();

	return useMemo(() => {
		const supporterComments = petition.commentsConnection.nodes.filter(
			(c): c is SupporterComment => c.__typename === 'PetitionComment',
		);
		return {
			data: {
				supporterComments,
				petition,
			},
			actions: {},
		};
	}, [petition]);
}
