import { useMemo } from 'react';

import { usePetitionDetailsPageUserInfo } from 'src/app/pages/petitionGamma/details/pageContext';
import { useCurrentUserRolesAsync } from 'src/app/shared/hooks/session';
import { isLoaded } from 'src/app/shared/utils/async';

type Result = ModelHookResult<
	{
		canAdd: boolean;
		canDelete: boolean;
	},
	EmptyObject
>;

export function useMediaHitsPermissions(): Result {
	const { canEdit } = usePetitionDetailsPageUserInfo();

	// TODO(CHANGE-67838): This is async because userData is disallowed in SSR for guests.
	const userRolesAsyncState = useCurrentUserRolesAsync();
	const isCampaignOrSupportStaff = isLoaded(userRolesAsyncState)
		? userRolesAsyncState.value.campaigns || userRolesAsyncState.value.support
		: false;

	return useMemo(() => {
		return {
			data: {
				canAdd: isCampaignOrSupportStaff,
				canDelete: canEdit || isCampaignOrSupportStaff,
			},
			actions: {},
		};
	}, [canEdit, isCampaignOrSupportStaff]);
}
