import { useMemo } from 'react';

import type { PetitionPhoto } from 'src/app/pages/petitionGamma/details/shared/types';
import { useProcessedImage } from 'src/app/shared/hooks/image';

import { usePetitionDetails } from './petitionDetails';

export function usePhoto(): PetitionPhoto | undefined {
	const { photo } = usePetitionDetails();
	const photoSmall = useProcessedImage(photo?.petitionSmall);
	const photoMedium = useProcessedImage(photo?.petitionMedium);

	return useMemo(() => {
		if (!photoSmall || !photoMedium) return undefined;
		return {
			mobileUrl: photoSmall.url,
			url: photoMedium.url,
			processing: photoSmall.processing || photoMedium.processing,
		};
	}, [photoSmall, photoMedium]);
}
