import React from 'react';

import { Field, Form } from 'react-final-form';

import { Button } from '@change-corgi/design-system/components/actions';
import { Input } from '@change-corgi/design-system/components/forms';
import { Box, Flex } from '@change-corgi/design-system/layout';
import { Heading, Text } from '@change-corgi/design-system/typography';

import type { MediaHitValues } from '../../shared/context/types';

import { MediaSubmitMessages } from './components/MediaHitSubmitMessages';
import { useAddMediaHit } from './hooks/useAddMediaLinks';

export function AddMediaHit(): React.JSX.Element | null {
	const {
		data: { loading },
		actions: { validateLink, handleSubmitMediaHit: handleSubmitMediaLink },
	} = useAddMediaHit();

	return (
		<>
			<Box
				data-testid="add-media-wrapper"
				sx={{
					background: 'primary-greyBackground',
					borderRadius: 'standard',
				}}
				p={16}
			>
				<Heading as="h3" size={['h5', 'h4']} mb={8}>
					Embed media to your petition
				</Heading>
				<Text as="p" size="small" mb={8} sx={{ whiteSpace: 'pre-line' }}>
					Media articles and social posts about your campaign can be powerful to influence decision makers. Link them
					below!
				</Text>

				<Form<MediaHitValues>
					onSubmit={handleSubmitMediaLink}
					initialValues={{ link: '' }}
					subscription={{ pristine: true, submitting: true }}
				>
					{({ handleSubmit }) => (
						<form onSubmit={handleSubmit}>
							<Flex
								sx={{
									flexDirection: ['column', null, 'row'],
									alignItems: ['', null, 'flex-start'],
									gap: 16,
								}}
								mb={8}
							>
								<Box sx={{ width: ['100%', null, '60%'] }}>
									<Field<string> name="link" type="text" validate={validateLink}>
										{({ input, meta }) => {
											return (
												<Input
													{...input}
													id="add-media-link"
													type="text"
													label="Media URL"
													hideLabel
													placeholder="https://www.link.com"
													data-testid="add-media-link-input"
													sx={{ width: '100%', height: '44px' }}
													error={
														(meta.active || meta.submitFailed) && typeof meta.error === 'string'
															? meta.error
															: undefined
													}
												/>
											);
										}}
									</Field>
								</Box>
								<Button
									variant="secondaryEmphasis"
									type="submit"
									data-testid="add-media-link-button"
									loading={loading}
									sx={{ width: ['100%', null, '40%'] }}
								>
									Add Media URL
								</Button>
							</Flex>
						</form>
					)}
				</Form>
			</Box>

			<MediaSubmitMessages />
		</>
	);
}
