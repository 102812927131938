import { ClientRender } from '@change-corgi/core/react/ssr/render';
import { Box, Flex } from '@change-corgi/design-system/layout';

import type { SupporterComment, SupporterCommentPetition } from '../../../../shared/types';
import { CommentLikes } from '../../../CommentLikes';
import { ReportComment } from '../../../ReportComment';

export function SupporterCommentCardFooter({
	supporterComment,
	petition,
}: {
	supporterComment: SupporterComment;
	petition: SupporterCommentPetition;
}): React.JSX.Element {
	return (
		<ClientRender placeholder={<Box sx={{ height: 24 }} />}>
			<Flex sx={{ gap: '10px' }}>
				<CommentLikes comment={supporterComment} />
				<ReportComment comment={supporterComment} petitionId={petition.id} petitionSlug={petition.slug} />
			</Flex>
		</ClientRender>
	);
}
