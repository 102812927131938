/* eslint-disable jsx-a11y/media-has-caption */
import { Box, Flex } from '@change-corgi/design-system/layout';

import { DeleteVideo, LikeVideo, ReportVideo, VideoPlayer } from 'src/app/shared/components/petitionVideo';

import type { SupporterVideo } from '../../types';
import { PlayPauseIcons } from '../PlayPauseIcons';

import { useVideoComponent } from './hooks/useVideoComponent';

type VideoComponentProps = {
	video: SupporterVideo;
	currentlyPlaying: string;
	setCurrentlyPlaying: (videoUrl: string) => void;
	removeVideoFromState: (videoId?: string) => void;
};

/**
 * Renders the video player in an inline block. Includes:
 *  - Fixed-height video container
 *  - Play/pause icon over the video
 *  - Like, delete, and report buttons beneath the video
 */
export function VideoComponent({
	video,
	currentlyPlaying,
	setCurrentlyPlaying,
	removeVideoFromState,
}: VideoComponentProps): React.JSX.Element | null {
	const { id: videoId, videoUrl, user } = video;

	const {
		data: { isPlaying, videoRef, petitionId, petitionSlug },
		actions: { toggleVideoPlayback, trackVideoError, setIsPlaying },
	} = useVideoComponent({ video, currentlyPlaying, setCurrentlyPlaying });

	return (
		<Flex
			key={videoId}
			sx={{
				flexDirection: 'column',
				// Our theme passes `min-width: 0` by default, but here we want these elements to trigger
				// scrolling rather than shrink.
				minWidth: 'auto',
			}}
			onFocus={(event) => {
				event.target.scrollIntoView({ behavior: 'smooth', inline: 'center', block: 'nearest' });
			}}
		>
			<Box
				sx={{
					position: 'relative',
					display: 'flex',
					height: '200px',
				}}
				data-testid={`video-${videoId}-with-icon`}
			>
				<PlayPauseIcons isPlaying={isPlaying} />
				<VideoPlayer
					creator={user?.shortDisplayName}
					ref={videoRef}
					tabIndex={0}
					videoUrl={videoUrl}
					videoId={videoId}
					onClick={() => {
						void toggleVideoPlayback();
					}}
					onKeyDown={(event) => {
						if (event.key === 'Enter') {
							void toggleVideoPlayback();
						}
					}}
					onPause={(_) => {
						setIsPlaying(false);
					}}
					onError={() => {
						// Unfortunately the event passed to this handler doesn't provide any error details.
						trackVideoError(new Error('Video element error'));
					}}
				/>
			</Box>

			<Flex sx={{ gap: 8 }} my={8}>
				<LikeVideo video={video} petitionId={petitionId} renderedOn="petition_page" />
				<DeleteVideo
					video={video}
					petitionId={petitionId}
					afterDeleteAction={removeVideoFromState}
					renderedOn="petition_page"
				/>
				<ReportVideo video={video} petitionId={petitionId} petitionSlug={petitionSlug} />
			</Flex>
		</Flex>
	);
}
