export type ScrollTargets = {
	first: HTMLElement | null;
	last: HTMLElement | null;
	current: HTMLElement | null;
	next: HTMLElement | null;
};

/**
 * Returns the elements that could be relevant scroll targets relative to the current scroll position.
 */
export const findScrollTargets = (scroller: HTMLElement) => {
	const targets: ScrollTargets = {
		first: null,
		last: null,
		current: null,
		next: null,
	};

	// This loops through every child, but that shouldn't be a performance problem because we only
	// intend to have ~20 children to check every 2 seconds.
	Array.from(scroller.children).forEach((el) => {
		if (!(el instanceof HTMLElement)) {
			return;
		}

		if (!targets.first) {
			targets.first = el;
		}

		if (!targets.current) {
			if (checkScrollVisibility(scroller, el).left) {
				targets.current = el;
			}
		} else if (!targets.next) {
			targets.next = el;
		}

		targets.last = el;
	});

	return targets;
};

export type ScrollVisibility = {
	left: boolean;
	right: boolean;
};

export const checkScrollVisibility = (scroller: HTMLElement, child: HTMLElement): ScrollVisibility => {
	const left = scroller.scrollLeft <= child.offsetLeft;
	const right = child.offsetLeft + child.offsetWidth <= scroller.scrollLeft + scroller.offsetWidth;
	return { left, right };
};
