import { useMemo } from 'react';

import {
	usePetitionDetailsPageData,
	usePetitionDetailsPageFcm,
	usePetitionDetailsPageUserData,
	usePetitionDetailsPageUserInfo,
} from 'src/app/pages/petitionGamma/details/pageContext';

import { usePetitionStats } from '../../../hooks/petitionStats';

import type { MobileModalContext } from './types';

export function useMobileModalContext(): MobileModalContext {
	const pageData = usePetitionDetailsPageData();
	const pageUserData = usePetitionDetailsPageUserData();
	const userInfo = usePetitionDetailsPageUserInfo();
	const stats = usePetitionStats();
	const fcm = usePetitionDetailsPageFcm();

	return useMemo(
		() => ({ pageData, pageUserData, userInfo, stats, fcm }),
		[pageData, pageUserData, userInfo, stats, fcm],
	);
}
