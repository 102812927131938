import React from 'react';

import { Translate } from '@change-corgi/core/react/i18n';
import { Box } from '@change-corgi/design-system/layout';
import { Heading } from '@change-corgi/design-system/typography';

import { DescriptionContent } from './components/DescriptionContent';

export const DescriptionContainer: React.FC = () => {
	return (
		<Box>
			<Heading as="h2" size="h3" mb="16">
				<Translate value="corgi.petition_gamma.details.description_title" />
			</Heading>

			<DescriptionContent />
		</Box>
	);
};
