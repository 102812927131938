import React from 'react';

import { Translate } from '@change-corgi/core/react/i18n';
import { VisuallyHidden } from '@change-corgi/design-system/a11y';

import { MIN_SIGNERS } from '../../constants';
import { useRecentSignersConnection } from '../../hooks/useRecentSignersConnection';

export const AccessibleDescription: React.FC = () => {
	const recentSigners = useRecentSignersConnection();

	if (recentSigners.length < MIN_SIGNERS) {
		return null;
	}

	const firstSigner = recentSigners[0];
	const othersCount = recentSigners.length - 1;

	return (
		<VisuallyHidden>
			<Translate
				value="corgi.petition_gamma.recent_signers.accessible_description"
				replacements={{
					firstSignerName: firstSigner.displayName,
					othersCount,
				}}
			/>
		</VisuallyHidden>
	);
};
