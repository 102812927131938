import type { JSX } from 'react';

import { Box } from '@change-corgi/design-system/layout';

import { usePetitionDetails } from '../../hooks/petitionDetails';
import { useUserInfo } from '../../hooks/userInfo';
import { RespondContainer } from '../Respond';

import { SideClosedContainer } from './SideClosed';
import { SideSignedContainer } from './SideSigned';
import { SideUnsignedContainer } from './SideUnsigned';
import { SideVictoryContainer } from './SideVictory';

function SideContainerInner(): JSX.Element {
	const userInfo = useUserInfo();
	const { status } = usePetitionDetails();

	if (userInfo.decisionMaker) {
		return <RespondContainer />;
	}
	if (status === 'CLOSED') {
		return <SideClosedContainer />;
	}
	if (status === 'VICTORY') {
		return <SideVictoryContainer />;
	}
	if (userInfo.signed) {
		// TODO: Show share buttons for victory, closed, starter, non-promotablee
		return <SideSignedContainer />;
	}
	return <SideUnsignedContainer />;
}

export function SideContainer(): JSX.Element {
	return (
		<Box
			px={24}
			pb={24}
			mt={8}
			pt={24}
			sx={{
				height: '100%',
				borderRadius: '8px',
				borderColor: 'primary-greyBackground',
				boxShadow: '0px 0px 16px 0px #00000026',
			}}
		>
			<SideContainerInner />
		</Box>
	);
}
