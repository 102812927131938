import { Avatar } from '@change-corgi/design-system/components/media';
import { Box, Flex } from '@change-corgi/design-system/layout';
import { Text } from '@change-corgi/design-system/typography';

import type { SupporterComment } from '../../../../shared/types';

export function SupporterCommentCardHeader({
	supporterComment,
}: {
	supporterComment: SupporterComment;
}): React.JSX.Element {
	return (
		<Flex sx={{ gap: '10px' }}>
			<Avatar
				variant="subtle"
				src={supporterComment.user.photo?.userSmall.url}
				mr={10}
				size={50}
				loading="lazy"
				alt={`Avatar of ${supporterComment.user.shortDisplayName}`}
			/>

			<Flex sx={{ flexDirection: 'column' }}>
				<Flex>
					<Text sx={{ fontWeight: '700' }}>{supporterComment.user.shortDisplayName}</Text>
					{supporterComment.user.city && <Text sx={{ fontWeight: '400' }}>{`, ${supporterComment.user.city}`}</Text>}
				</Flex>
				<Box>
					<Text color="typography-secondary">{supporterComment.createdAtRelativeLocalized}</Text>
				</Box>
			</Flex>
		</Flex>
	);
}
