import { useCallback, useMemo } from 'react';

import { usePetitionDetailsPageFcm } from 'src/app/pages/petitionGamma/details/pageContext';
import { useCampaignTracking } from 'src/app/shared/hooks/campaignTracking';

type Result = ModelHookResult<
	{ enabled: boolean; url: string },
	{
		handleMediaInquiryClick: () => void;
	}
>;

export function usePetitionStarterMediaInquiry(petitionId: string): Result {
	const { track } = useCampaignTracking();
	const fcm = usePetitionDetailsPageFcm();

	const isMediaInquiryEnabled = useMemo(
		() => fcm.petitionStarterMediaInquiryConfig?.enabled ?? false,
		[fcm.petitionStarterMediaInquiryConfig],
	);
	const url = useMemo(() => fcm.petitionStarterMediaInquiryConfig?.url || '', [fcm.petitionStarterMediaInquiryConfig]);
	const enabled = useMemo(() => isMediaInquiryEnabled && !!url, [isMediaInquiryEnabled, url]);

	const handleMediaInquiryClick = useCallback(() => {
		void track('gamma_media_inquiries_click', { petition_id: petitionId });
	}, [petitionId, track]);

	return {
		data: {
			enabled,
			url,
		},
		actions: {
			handleMediaInquiryClick,
		},
	};
}
