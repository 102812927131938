import { Box } from '@change-corgi/design-system/layout';
import { Heading } from '@change-corgi/design-system/typography';

import { ResponsiveBox } from 'src/app/pages/petitionGamma/details/shared/components';
import { AfterMediaWaypoint } from 'src/app/pages/petitionGamma/details/shared/waypoints';

import { usePetitionDetails } from '../hooks/petitionDetails/petitionDetails';
import { usePhoto } from '../hooks/photo';
import { useFirstMovement } from '../hooks/useFirstMovement';

import { MediaContainer } from './Media';
import { MovementContainer } from './Movement';

// TODO: This should be renamed because it handles both small and large viewport heading content.
export function HeadingContainerMobile(): JSX.Element {
	const details = usePetitionDetails();
	const photo = usePhoto();
	const firstMovement = useFirstMovement();

	return (
		<Box data-testid="heading-container-mobile">
			<Box px={0} pt={[0, 8]} pb={[null, 8]}>
				{!!photo && <MediaContainer key={details.id} photo={photo} />}
				<AfterMediaWaypoint />
			</Box>

			<ResponsiveBox viewportSize="small">
				<Box
					pt={[16, 40]}
					px={[16, 0]}
					mt={[photo ? -45 : 0, 0]}
					mb={[16, 0]}
					mx={16}
					sx={{
						position: 'relative',
						background: 'white',
						borderRadius: '10px',
					}}
				>
					<Heading
						as="h1"
						size="h2"
						pt={0}
						breakWord
						data-qa="petition-title"
						sx={{
							textAlign: ['center'],
							lineHeight: '44px',
							fontSize: '30px',
						}}
					>
						{details.ask}
					</Heading>
				</Box>
			</ResponsiveBox>

			{firstMovement && (
				<Box mb={[16, 8]} px={[16, 0]}>
					<MovementContainer movement={firstMovement} alwaysExpanded={!photo} />
				</Box>
			)}
		</Box>
	);
}
