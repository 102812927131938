import type { JSX } from 'react';

import type { ErrorHandlerProps } from 'src/app/pages/petitionGamma/details/pageContext';

export function PetitionDetailsErrorHandler({ context }: ErrorHandlerProps): JSX.Element {
	if (context.error === 'user_info_load_failure') {
		throw new Error(`Failed to retrieve data for PetitionGamma page (error: ${context.error})`, {
			cause: context.cause,
		});
	}
	throw new Error(`Failed to retrieve data for PetitionGamma page (error: ${context.error})`);
}
