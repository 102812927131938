import React from 'react';

import { Box } from '@change-corgi/design-system/layout';

import { MIN_SIGNERS } from '../../constants';
import { useRecentSignersConnection } from '../../hooks/useRecentSignersConnection';
import { AccessibleDescription } from '../AccessibleDescription';
import { ActivityPill, ActivityScroller } from '../ActivityScroller';

export const RecentSignersViewportSmall: React.FC = () => {
	const recentSigners = useRecentSignersConnection();

	if (recentSigners.length < MIN_SIGNERS) {
		return null;
	}
	return (
		<Box
			mb={24}
			sx={{
				// This makes the element overlap the padding from the MainContainer so that the list appears to scroll
				// off the edges of the device (viewport).
				marginX: -16,
			}}
			data-testid="recent-signers-mobile"
		>
			<ActivityScroller aria-hidden>
				{recentSigners.map((signer) => (
					<ActivityPill
						key={signer.id}
						title={signer.displayName}
						time={signer.timestampRelativeLocalized}
						sx={{
							// Reintroduce the margin within the scrolling area:
							marginLeft: 16,
							scrollMarginLeft: 16,
						}}
					/>
				))}
			</ActivityScroller>

			<AccessibleDescription />
		</Box>
	);
};
