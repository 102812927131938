import type { JSX } from 'react';

import { SectionMessage } from '@change-corgi/design-system/components/alerts';
import { Image } from '@change-corgi/design-system/components/media';
import { Box, Flex } from '@change-corgi/design-system/layout';
import { Text } from '@change-corgi/design-system/typography';

import { EmbeddedMedia } from 'src/app/shared/components/embeddedMedia';

import { useMediaHitsContext } from '../../shared/context';
import type { MediaHitEmbed } from '../../shared/context';
import { useMediaHitsPermissions } from '../../shared/hooks/useMediaHitsPermissions';
import { GammaDeleteMediaHit } from '../DeleteMediaHit';
import { LoadingSkeleton } from '../LoadingSkeleton';

export function MediaHitList(): JSX.Element | null {
	const {
		data: { mediaEmbeds, mediaHitComments, loadingAllEmbeds, loadingSingleEmbed },
		actions: { removeMedia },
	} = useMediaHitsContext();

	if (!(loadingAllEmbeds || loadingSingleEmbed) && mediaEmbeds.length === 0) return null;

	return (
		<Box data-testid="media-hit-list">
			{loadingSingleEmbed && <LoadingSkeleton />}
			{loadingAllEmbeds && <>{mediaHitComments?.map(({ id }) => <LoadingSkeleton key={id} />)}</>}
			{!loadingAllEmbeds &&
				mediaEmbeds.map((media) => {
					return <MediaHit media={media} removeMedia={removeMedia} key={media.id} />;
				})}
		</Box>
	);
}

function MediaHit({ media, removeMedia }: { media: MediaHitEmbed; removeMedia: (mediaId: string) => void }) {
	const {
		data: { canDelete },
	} = useMediaHitsPermissions();

	return (
		<>
			{!media.valid && canDelete && (
				<Box key={media.id} data-testid={`media-hit-${media.id}`}>
					<SectionMessage
						variant="warning"
						sx={{ backgroundColor: 'primary-greyBackground', color: 'black' }}
						title="This URL seems to be broken or non-existent."
						my={16}
					>
						Unable to generate a preview for the following link:
						<Text as="p" size="small">
							URL: {media.url}
						</Text>
					</SectionMessage>
					<GammaDeleteMediaHit media={media} removeMedia={removeMedia} />
				</Box>
			)}

			{media.valid && (
				<Box key={media.id} data-testid={`media-hit-${media.id}`} my={16} sx={{ position: 'relative' }}>
					{media.mediaSource === 'AUTOMATED' && (
						<Flex p={8}>
							<Image src="https://static.change.org/campaign_media_hits/stars_red.svg" alt="" mr={8} />
							<Text size="small">Detected by Change.org</Text>
						</Flex>
					)}
					<EmbeddedMedia html={media.html} />
					<Box my={8}>
						{/*
								This could use a repurposed DeleteVideo (src/app/shared/components/petitionVideo/components/DeleteVideo/index.tsx)
								button in the future should we open the feature to all users. For now the conditions under which to show this delete
								and some props differ DeleteVideo.
							*/}
						<GammaDeleteMediaHit media={media} removeMedia={removeMedia} />
					</Box>
				</Box>
			)}
		</>
	);
}
