import type { JSX, PropsWithChildren } from 'react';

import {
	PetitionDetailsPageDataContextProvider,
	PetitionDetailsPageFcmContextProvider,
	PetitionDetailsPageUserDataContextProvider,
	PetitionDetailsPageUserInfoContextProvider,
} from 'src/app/pages/petitionGamma/details/pageContext';

import { PetitionStatsStateProvider } from '../../../hooks/petitionStats';

import type { MobileModalContext } from './types';

export function MobileModalContextDecorator({
	stats,
	userInfo,
	pageData,
	pageUserData,
	fcm,
	children,
}: PropsWithChildren<MobileModalContext>): JSX.Element {
	return (
		<PetitionStatsStateProvider value={stats}>
			<PetitionDetailsPageUserInfoContextProvider userInfo={userInfo}>
				<PetitionDetailsPageDataContextProvider pageData={pageData}>
					<PetitionDetailsPageUserDataContextProvider pageUserData={pageUserData}>
						<PetitionDetailsPageFcmContextProvider pageFcm={fcm}>{children}</PetitionDetailsPageFcmContextProvider>
					</PetitionDetailsPageUserDataContextProvider>
				</PetitionDetailsPageDataContextProvider>
			</PetitionDetailsPageUserInfoContextProvider>
		</PetitionStatsStateProvider>
	);
}
